import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

const StyledNotificationNumber = styled(Typography)`
  min-width: 26px;
  min-height: 26px;
  background-color: var(--secondary-main);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  background: "#E9E9E9",
  borderRadius: "6px",
  padding: "3px",
  width: "fit-content",
  gap: "4px",
  display: "flex",
  height: "37px"
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  border: "none",
  borderRadius: "6px !important",
  textTransform: "none",
  padding: "8px 16px",
  color: "#6B6B6B",
  "&.Mui-selected": {
    backgroundColor: "#fff",
    color: "#464646",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#424242"
    }
  }
}));

export { StyledNotificationNumber, StyledToggleButtonGroup, StyledToggleButton };
