import { useMutation } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ApiError, UserDeviceTokenForCreateUpdateDto } from "types";
import { ErrorsUtils } from "utils";

export const useSetDeviceTokenMutation = () => {
  const mutation = useMutation({
    mutationKey: [UsersManagementService.setDeviceToken.mutationKey],
    mutationFn: UsersManagementService.setDeviceToken.request,
    onSuccess: () => {
      console.info("Device token set successfully");
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const setDeviceToken = (userId: string, data: UserDeviceTokenForCreateUpdateDto) => {
    return mutation.mutateAsync({
      userId,
      ...data
    });
  };

  const { isLoading } = useIsLoadingMutation(UsersManagementService.setDeviceToken.mutationKey);
  return {
    ...mutation,
    isLoading,
    setDeviceToken
  };
};
