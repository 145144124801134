import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";
import { useStore } from "stores";
import { observer } from "mobx-react-lite";
import { MenuItems } from "components/layout/menu";
import { items } from "components/layout/sidebar";
import { LogoSectionMobile } from "components/logo";
import { PrimeSimpleBar } from "components/common/prime-simple-bar";

type SidebarProps = {
  drawerOpen: boolean;
};
const Sidebar = ({ drawerOpen }: SidebarProps) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const { commonStore } = useStore();

  const drawer = (
    <>
      <LogoSectionMobile />
      <BrowserView>
        <PrimeSimpleBar
          sx={{
            maxHeight: "calc(100dvh - 69px)"
          }}
        >
          <MenuItems menuListItems={items} />
        </PrimeSimpleBar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuItems menuListItems={items} />
        </Box>
      </MobileView>
    </>
  );

  return (
    <Box
      component='nav'
      sx={{
        flexShrink: { md: 0 },
        width: matchUpMd ? "var(--drawer-width)" : "auto"
      }}
      aria-label='mailbox folders'
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor='left'
        open={drawerOpen}
        onClose={() => commonStore.toggleNavbar()}
        sx={{
          "& .MuiDrawer-paper": {
            width: "var(--drawer-width)",
            background: "var(--primary-main)",
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "69px"
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color='inherit'
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default observer(Sidebar);
