import { NotificationItem } from "components/layout/notifications";
import { Box, Stack } from "@mui/material";
import { PrimeSimpleBar } from "components/common";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { InfiniteData } from "@tanstack/react-query";
import { AppNotificationForReadDtoPaginatedList } from "types";
import CircularProgress from "@mui/material/CircularProgress";

type NotificationListProps = {
  notifications?: InfiniteData<AppNotificationForReadDtoPaginatedList>;
  fetchNextPage(): Promise<void>;
  isFetchingNextPage: boolean;
};
function NotificationList({ notifications, fetchNextPage, isFetchingNextPage }: NotificationListProps) {
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <Stack width="100%" border='1px solid #e9e9e9' borderRadius='16px' overflow='hidden'>
      <PrimeSimpleBar style={{ maxHeight: "calc(100dvh - 230px)" }}>
        {notifications?.pages.map(page => {
          return page.data.map(notification => <NotificationItem key={notification.id} notification={notification} />);
        })}
        {isFetchingNextPage && (
          <Stack borderTop="1px solid #e9e9e9" alignItems='center' height='40px'>
            <CircularProgress size='24px' sx={{ m: "10px auto" }} />
          </Stack>
        )}
        <Box ref={ref} />
      </PrimeSimpleBar>
    </Stack>
  );
}

export default NotificationList;
