import { useQuery } from "@tanstack/react-query";
import { DoctorManagementService } from "api";
import { useParams } from "react-router-dom";
import { AppointmentCalendar } from "types";
import moment from "moment";
import { DATE_TIME_FORMAT } from "app-constants";
import { UserUtils } from "utils";
import { useState } from "react";

export const useGetDoctorAvailabilitySlotsAndAppointmentsQuery = () => {
  const [currentDate, setCurrentDate] = useState<string>("");
  const { doctorId } = useParams<{ doctorId: string }>();
  function getYearAndMonth(): { year: number; month: number } {
    const inputDate = moment(currentDate, DATE_TIME_FORMAT.DATE);

    const year = inputDate.year();
    const month = inputDate.month() + 1; // Moment.js months are 0-indexed

    return { year, month };
  }
  const query = useQuery({
    queryKey: [DoctorManagementService.getDoctorAvailabilitySlotsAndAppointments.queryKey, getYearAndMonth().month, getYearAndMonth().year],
    queryFn: () =>
      DoctorManagementService.getDoctorAvailabilitySlotsAndAppointments.request({
        doctorId: doctorId!,
        currentDate
      }),
    select: data => {
      return data.appointments.map(appointmentCalendar => {
        const {
          patientUser,
          scheduleDetail: { date, duration }
        } = appointmentCalendar;

        const title = UserUtils.getFullName(patientUser);
        const startDate = new Date(`${date}T${duration?.startTime}`);
        const endDate = new Date(`${date}T${duration?.endTime}`);

        return { ...appointmentCalendar, title, startDate, endDate };
      }) as AppointmentCalendar[];
    }
  });
  return {
    ...query,
    setCurrentDate
  };
};
