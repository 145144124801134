import { Address, UserForMinimalReadDto } from "types";

export default class UserUtils {
  static getFullName(user?: UserForMinimalReadDto) {
    if (!user) return "";

    return `${user?.lastName ?? ""} ${user?.firstName ?? ""}`;
  }

  static getFullAddress(address?: Address | null) {
    if (!address) return "";

    return [address?.streetAddress, address?.city, address?.state, address?.zipCode].filter(address => !!address).join(", ");
  }
}
