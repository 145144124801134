function NotificationAlertIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#FF0000' fillOpacity='0.08' />
      <path
        d='M22.0048 21.8442C21.9495 21.5657 21.6757 21.276 21.3932 21.1879C21.2216 21.1348 17.8829 20.9135 15.9935 20.916C14.1041 20.9135 10.7654 21.1348 10.5938 21.1879C10.3126 21.2767 10.0376 21.5663 9.98227 21.8442C9.93966 22.0758 9.92611 22.3119 9.94196 22.547C9.95552 22.782 9.99749 23.0145 10.067 23.2395C10.1554 23.5092 10.4516 23.7638 10.7304 23.8238C10.9032 23.8601 14.1888 23.9107 15.9935 23.9107C17.7982 23.9107 21.0838 23.8601 21.2554 23.8238C21.5341 23.7638 21.8304 23.5092 21.9188 23.2395C21.9883 23.0145 22.0303 22.782 22.0438 22.547C22.0601 22.312 22.047 22.0759 22.0048 21.8442Z'
        fill='#FF0000'
      />
      <path
        d='M20.6886 15.8643C20.6495 13.3593 18.4648 11.333 15.9942 11.333C13.5236 11.333 11.3379 13.3608 11.2998 15.8658C11.2875 17.3227 11.3138 18.7794 11.3786 20.2358C11.7308 20.2102 12.1495 20.1833 12.5823 20.1589C13.9011 20.0843 15.1329 20.0414 15.962 20.0414H16.0245C16.8536 20.0414 18.087 20.0843 19.4045 20.1589C19.8373 20.1836 20.2561 20.2102 20.6083 20.2358C20.6737 18.7787 20.7004 17.3215 20.6886 15.8643ZM15.0061 14.0127C14.5086 14.143 14.0964 14.5364 13.9561 15.0152C13.9118 15.1663 13.8093 15.2937 13.6712 15.3693C13.533 15.4448 13.3705 15.4624 13.2193 15.4182C13.0682 15.3739 12.9409 15.2714 12.8653 15.1333C12.7897 14.9951 12.7721 14.8326 12.8164 14.6814C13.0754 13.7974 13.7992 13.1011 14.7054 12.8639C14.7812 12.8433 14.8602 12.8378 14.9381 12.8479C15.0159 12.858 15.091 12.8834 15.159 12.9227C15.2269 12.962 15.2864 13.0144 15.334 13.0768C15.3816 13.1392 15.4163 13.2105 15.4362 13.2864C15.456 13.3624 15.4607 13.4415 15.4498 13.5192C15.4389 13.597 15.4126 13.6718 15.3726 13.7393C15.3326 13.8068 15.2797 13.8658 15.2167 13.9127C15.1538 13.9596 15.0822 13.9936 15.0061 14.0127Z'
        fill='#FF0000'
      />
      <path
        d='M15.974 10.8125C16.2329 10.8125 16.4428 10.6026 16.4428 10.3437V8.46875C16.4428 8.20987 16.2329 8 15.974 8C15.7152 8 15.5053 8.20987 15.5053 8.46875V10.3437C15.5053 10.6026 15.7152 10.8125 15.974 10.8125ZM12.2832 11.4751C12.4131 11.7 12.7 11.7757 12.9235 11.6466C13.1477 11.5172 13.2246 11.2305 13.0951 11.0063L12.1576 9.38253C12.0281 9.15831 11.7415 9.08153 11.5173 9.21097C11.2931 9.34041 11.2162 9.62709 11.3457 9.85128L12.2832 11.4751ZM10.6988 13.8942C10.8282 13.6701 10.7514 13.3834 10.5272 13.2539L8.90341 12.3164C8.67919 12.187 8.3925 12.2638 8.26309 12.488C8.13362 12.7122 8.21044 12.9989 8.43466 13.1283L10.0585 14.0658C10.2817 14.1947 10.5688 14.1194 10.6988 13.8942ZM19.087 11.6466C19.3103 11.7755 19.5973 11.7002 19.7273 11.4751L20.6648 9.85128C20.7943 9.62709 20.7175 9.34041 20.4933 9.21097C20.2691 9.08147 19.9824 9.15831 19.853 9.38253L18.9155 11.0063C18.786 11.2305 18.8628 11.5172 19.087 11.6466ZM23.7475 12.488C23.618 12.2638 23.3314 12.187 23.1072 12.3164L21.4833 13.2539C21.2591 13.3834 21.1823 13.6701 21.3118 13.8942C21.4416 14.1192 21.7285 14.1949 21.9521 14.0658L23.5759 13.1283C23.8001 12.9989 23.8769 12.7122 23.7475 12.488Z'
        fill='#FF0000'
      />
    </svg>
  );
}

export default NotificationAlertIcon;
