import { ApiURLs, httpClient } from "api/core";
import { PaginatedDataTable, UserDeviceTokenForCreateUpdateDto, UserForCreateUpdateDto, UserForListDto, UserForReadDto } from "types";

const URL = ApiURLs.userManagement;

async function getUsers(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<UserForListDto>>(`${URL}?${queryParams}`);
}

async function getUserDetails(userId: string) {
  return await httpClient.get<UserForReadDto>(`${URL}/${userId}`);
}

async function disableUser(userId: string) {
  return await httpClient.put<void>(
    `${URL}/${userId}/disable`,
    {},
    {
      params: {
        userId
      }
    }
  );
}

async function enableUser(userId: string) {
  return await httpClient.put<void>(
    `${URL}/${userId}/enable`,
    {},
    {
      params: {
        userId
      }
    }
  );
}

async function createUser(user?: UserForCreateUpdateDto) {
  if (!user) return;
  return await httpClient.post<{ userId: string }>(URL, user);
}

async function updateUser(user: UserForCreateUpdateDto, userId: string) {
  return await httpClient.put<void>(`${URL}/${userId}`, user);
}

async function deleteUser(userId: string) {
  return await httpClient.delete<void>(`${URL}/${userId}`);
}

async function setDeviceToken(data: UserDeviceTokenForCreateUpdateDto & { userId: string }) {
  return await httpClient.put<void>(`${URL}/${data.userId}/set-device-token`, data);
}
export const UsersManagementService = {
  list: {
    request: getUsers,
    queryKey: "userList"
  },
  enable: {
    request: enableUser,
    queryKey: "enableUser"
  },
  disable: {
    request: disableUser,
    queryKey: "disableUser"
  },
  details: {
    request: getUserDetails,
    queryKey: "details"
  },
  create: {
    request: createUser,
    queryKey: "createUser"
  },
  update: {
    request: updateUser,
    queryKey: "updateUser"
  },
  delete: {
    request: deleteUser,
    queryKey: "deleteUser"
  },
  setDeviceToken: {
    request: setDeviceToken,
    mutationKey: "setDeviceToken"
  }
};
