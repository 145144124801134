import { EAppointmentType, Option } from "types";

const OPTIONS: Option[] = [
  {
    id: "InitialEvaluation",
    label: "Initial Evaluation"
  },
  {
    id: "ReEvaluation",
    label: "Re-Evaluation"
  },
  {
    id: "Deposition",
    label: "Deposition"
  },
  {
    id: "Supplemental",
    label: "Supplemental"
  }
];

const DATATABLE_LIST = [
  {
    value: EAppointmentType.InitialEvaluation,
    text: "Initial Evaluation"
  },
  {
    value: EAppointmentType.ReEvaluation,
    text: "Re Evaluation"
  },
  {
    value: EAppointmentType.Supplemental,
    text: "Supplemental"
  },
  {
    value: EAppointmentType.Deposition,
    text: "Deposition"
  }
];

const APPOINTMENT_OPTIONS: Option[] = [
  {
    id: "InitialEvaluation",
    label: "Initial Evaluation"
  },
  {
    id: "ReEvaluation",
    label: "Re-Evaluation"
  },
  {
    id: "Deposition",
    label: "Deposition"
  }
];

const TYPE = {
  [EAppointmentType.InitialEvaluation]: "Initial Evaluation",
  [EAppointmentType.ReEvaluation]: "Re-Evaluation",
  [EAppointmentType.Supplemental]: "Supplemental",
  [EAppointmentType.Deposition]: "Deposition"
};

const APPOINTMENT_TYPES = Object.freeze({
  OPTIONS,
  TYPE,
  APPOINTMENT_OPTIONS,
  DATATABLE_LIST
});

export default APPOINTMENT_TYPES;
