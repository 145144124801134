import { useMutation } from "@tanstack/react-query";
import { TaskManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

type AssignUserToTaskMutationPayload = {
  appointmentTaskId: string;
  userId: string;
};
export const useAssignUserToTaskMutation = () => {
  const mutation = useMutation({
    mutationKey: [TaskManagementService.assignUserToTask.mutationKey],
    mutationFn: ({ appointmentTaskId, userId }: AssignUserToTaskMutationPayload) => TaskManagementService.assignUserToTask.request(appointmentTaskId, userId),
    onSuccess: () => {
      SnackbarUtils.success("User assigned to task successfully");
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const assignUserToTask = (appointmentTaskId: string, userId: string) => {
    return mutation.mutateAsync({ appointmentTaskId, userId });
  };

  const { isLoading } = useIsLoadingMutation(TaskManagementService.assignUserToTask.mutationKey);

  return {
    ...mutation,
    assignUserToTask,
    isLoading
  };
};
