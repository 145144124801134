import { styled } from "@mui/material/styles";
import { Theme } from "@mui/material/styles/createTheme";

type MainTagProps = {
  theme: Theme;
  open: boolean;
};
const MainTag = styled("main", { shouldForwardProp: (prop: any) => prop !== "open" })(({ theme, open }: MainTagProps) => ({
  ...(theme.typography as any).mainContent,
  backgroundColor: theme.palette.primary,
  width: "100%",
  minHeight: "calc(100vh - 88px)",
  flexGrow: 1,
  padding: "20px",
  marginTop: "70px",
  borderRadius: "12px",
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: "var(--drawer-ml)",
      width: "calc(100% - var(--drawer-width))"
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: "calc(100% - var(--drawer-width))",
      padding: "16px"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: "calc(100% - var(--drawer-width))",
      padding: "16px",
      marginRight: "10px"
    }
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: "calc(100% - var(--drawer-width))",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px"
    }
  })
}));

export default MainTag;
