import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const NotificationsPageList = WithLoadable(lazy(() => import("pages/notifications/NotificationsPage")));

export const NotificationsRoutes: RouteObject = {
  path: AppRoutes.notifications.root,
  element: <AuthorizationGuard permissions={[AppPermissions.AppNotificationManagement.Actions.View]} />,
  children: [
    {
      index: true,
      element: <NotificationsPageList />
    }
  ]
};
