import moment from "moment";
import { DATE_TIME_FORMAT } from "app-constants";

export default class DateUtils {
  
  static formatDate(date?: string | null) {
    if(!date) return "";
    const formattedDate = moment.parseZone(date);
    return formattedDate.format("MMMM D, YYYY");
  }

  // month year
  static formatMonthYear(date: string) {
    if(!date) return "";
    const formattedDate = moment.parseZone(date);
    return formattedDate.format("MMMM YYYY");
  }

  static fullDateFormat(date: string) {
    if(!date) return "";
    const formattedDate = moment.parseZone(date);
    return formattedDate.format(DATE_TIME_FORMAT.FULL_DATE_FORMAT);
  }
  
  static formatMinutes(num: number): string {
    const duration = moment.duration(num, "minutes");
    return moment.utc(duration.asMilliseconds()).format("HH:mm");
  }

  static formatToFullTime(time: string) {
    return moment(time, DATE_TIME_FORMAT.FULL_TIME);
  }

  static formatToTime(time: string) {
    if(!time) return "";
    return moment(`1970-01-01T${time}`).format(DATE_TIME_FORMAT.TIME);
  }
}