import { useQuery } from "@tanstack/react-query";
import { UsersManagementService } from "api";
import { useStore } from "stores";

export const useProfileQuery = () => {
  const { commonStore } = useStore();
  const profileQuery = useQuery({
    queryKey: [UsersManagementService.details.queryKey, "me"],
    queryFn: async () => {
      const response = await UsersManagementService.details.request("me");
      commonStore.setUserDetails(response);
      return response;
    },
    enabled: false,
    retry: false
  });

  const onFetchProfile = () => {
    return profileQuery.refetch();
  };

  return {
    ...profileQuery,
    onFetchProfile
  };
};
