import { useMutation } from "@tanstack/react-query";
import { TaskManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError } from "types";

export const useUnAssignUserFromTaskMutation = () => {
  const mutation = useMutation({
    mutationKey: [TaskManagementService.unAssignUserFromTask.mutationKey],
    mutationFn: TaskManagementService.unAssignUserFromTask.request,
    onSuccess: () => {
      SnackbarUtils.success("User unassigned from task successfully");
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const unAssignUserFromTask = (appointmentTaskId: string) => {
    return mutation.mutateAsync(appointmentTaskId);
  };

  const { isLoading } = useIsLoadingMutation(TaskManagementService.unAssignUserFromTask.mutationKey);

  return {
    ...mutation,
    unAssignUserFromTask,
    isLoading
  };
};
