import { ApiURLs, httpClient } from "api/core";
import {
  ActiveReportForCreateUpdateDto,
  ActiveReportForListDto,
  ActiveReportForReadDto,
  PaginatedDataTable, QualityApprovalInformation
} from "types";

const URL = ApiURLs.activeReportManagement;
async function createActiveReport(activeReport: ActiveReportForCreateUpdateDto) {
  return await httpClient.post<void>(`${URL}`, activeReport);
}
async function updateActiveReport(activeReportId: string, activeReport: ActiveReportForCreateUpdateDto) {
  return await httpClient.put<void>(`${URL}/${activeReportId}`, activeReport);
}
async function getActiveReport(activeReportId: string) {
  return await httpClient.get<ActiveReportForReadDto>(`${URL}/${activeReportId}`);
}

async function setFinalReportUrl(activeReportId: string, url: string) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/set-final-file-url`, {
    url
  });
}

async function setSetQualityApproval(activeReportId: string, qualityApprovalInformation: QualityApprovalInformation) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/set-quality-approval`, {
    qualityApprovalInformation
  });
}

async function signReport(activeReportId: string) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/sign`, {});
}

async function unsignReport(activeReportId: string) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/unsign`, {});
}

async function archiveReport(activeReportId: string) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/archive`, {});
}

async function getActiveReports(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<ActiveReportForListDto>>(`${URL}?${queryParams}`);
}

async function moveToFinancialAndBilling(activeReportId: string) {
  return await httpClient.put<void>(`${URL}/${activeReportId}/move-to-financial-and-billing`, {});
}

async function deleteActiveReport(activeReportId: string) {
  return await httpClient.delete<void>(`${URL}/${activeReportId}`);
}

export const ActiveReportService = {
  create: {
    request: createActiveReport,
    queryKey: "createActiveReport"
  },
  update: {
    request: updateActiveReport,
    queryKey: "updateActiveReport"
  },
  profile: {
    request: getActiveReport,
    queryKey: "getActiveReport"
  },
  list: {
    request: getActiveReports,
    queryKey: "getActiveReports"
  },
  finalReport: {
    request: setFinalReportUrl,
    queryKey: "setFinalReportUrl"
  },
  sign: {
    request: signReport,
    queryKey: "signReport"
  },
  unsign: {
    request: unsignReport,
    queryKey: "unsignReport"
  },
  archive: {
    request: archiveReport,
    queryKey: "archiveReport"
  },
  qualityApproval: {
    request: setSetQualityApproval,
    queryKey: "setSetQualityApproval"
  },
  moveToFinancialAndBilling: {
    request: moveToFinancialAndBilling,
    queryKey: "moveToFinancialAndBilling"
  },
  delete: {
    request: deleteActiveReport,
    mutationKey: "delete-active-report"
  }
};