import { useInfiniteQuery } from "@tanstack/react-query";
import { NotificationsService } from "api";
import { MRT_ColumnFiltersState } from "material-react-table";

type UseGetNotificationsListQueryProps = {
  showUnread: boolean;
  filters?: MRT_ColumnFiltersState;
};
export const useGetNotificationsListQuery = ({ showUnread, filters }: UseGetNotificationsListQueryProps) => {
  const query = useInfiniteQuery(
    [NotificationsService.notifications.queryKey, showUnread, JSON.stringify(filters)],
    ({ pageParam = 1 }) => {
      const queryParam = new URLSearchParams();
      queryParam.append("pageNumber", (+pageParam).toString());
      queryParam.append("pageSize", "20");
      queryParam.append("showUnread", showUnread.toString());
      if (filters) {
        filters.forEach(filter => {
          if (Array.isArray(filter.value)) {
            filter.value.forEach(value => {
              queryParam.append(filter.id, value);
            });
            return;
          }
          queryParam.append(filter.id, `${filter.value}`);
        });
      }
      return NotificationsService.notifications.request(queryParam.toString());
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) return undefined;
        return pages.length + 1;
      }
    }
  );

  return {
    ...query
  };
};
