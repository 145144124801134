import { Navigate, RouteObject } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { FormLayout } from "layout";

const FinancialsDetails = WithLoadable(lazy(() => import("pages/financials/FinancialsDetailsPage")));
const FinancialsDetailsProfilePage = WithLoadable(lazy(() => import("pages/financials/FinancialDetailsProfilePage")));
const PaymentCreatePage = WithLoadable(lazy(() => import("pages/billing-management/PaymentCreatePage")));
const PaymentListPage = WithLoadable(lazy(() => import("pages/billing-management/PaymentsListPage")));
const PaymentProfilePage = WithLoadable(lazy(() => import("pages/billing-management/PaymentProfilePage")));
const PaymentUpdatePage = WithLoadable(lazy(() => import("pages/billing-management/PaymentUpdatePage")));
const ReportsPage = WithLoadable(lazy(() => import("pages/financials/ReportsPage")));
const AgingReportListPage = WithLoadable(lazy(() => import("pages/billing-management/AgingReportListPage")));

export const BillingsRoutes: RouteObject = {
  path: AppRoutes.financials.root,
  children: [
    {
      index: true,
      element: <Navigate to={AppRoutes.financials.details} replace />
    },
    {
      path: AppRoutes.financials.details,
      element: (
        <AuthorizationGuard
          permissions={[
            AppPermissions.BillingManagement.Actions.DoctorView,
            AppPermissions.BillingManagement.Actions.BillerView,
            AppPermissions.BillingManagement.Actions.FullView,
            AppPermissions.BillingManagement.Actions.FinanceView
          ]}
        />
      ),
      children: [{ index: true, element: <FinancialsDetails /> }]
    },
    {
      path: AppRoutes.financials.doctorAgingReport,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.ViewAgingReports]} />,
      children: [{ index: true, element: <AgingReportListPage /> }]
    },
    {
      path: `${AppRoutes.financials.details}/${AppRoutes.financials.profile}/:appointmentFinancialId`,
      element: (
        <AuthorizationGuard
          permissions={[
            AppPermissions.BillingManagement.Actions.DoctorView,
            AppPermissions.BillingManagement.Actions.BillerView,
            AppPermissions.BillingManagement.Actions.FullView,
            AppPermissions.BillingManagement.Actions.FinanceView
          ]}
        />
      ),
      children: [{ index: true, element: <FinancialsDetailsProfilePage /> }]
    },
    {
      path: AppRoutes.financials.payments.root,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.ViewPayments]} />,
      children: [{ index: true, element: <PaymentListPage /> }]
    },
    {
      path: `${AppRoutes.financials.payments.root}/${AppRoutes.financials.payments.profile}/:paymentId`,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.ViewPayments]} />,
      children: [{ index: true, element: <PaymentProfilePage /> }]
    },
    {
      path: AppRoutes.financials.reports,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.ViewFinancialReports]} />,
      children: [{ index: true, element: <ReportsPage /> }]
    }
  ]
};

export const BillingsFormsRoutes: RouteObject = {
  path: AppRoutes.financials.root,
  element: <FormLayout />,
  children: [
    {
      path: `${AppRoutes.financials.payments.root}/${AppRoutes.financials.payments.create}`,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.CreatePayment]} />,
      children: [{ index: true, element: <PaymentCreatePage /> }]
    },
    {
      path: `${AppRoutes.financials.payments.root}/${AppRoutes.financials.payments.update}/:paymentId`,
      element: <AuthorizationGuard permissions={[AppPermissions.BillingManagement.Actions.UpdatePayment]} />,
      children: [{ index: true, element: <PaymentUpdatePage /> }]
    }
  ]
};
