import * as React from "react";
import { PropsWithChildren, ReactNode } from "react";
import Box from "@mui/material/Box";
import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardHeader, CardHeaderProps } from "@mui/material";
import { CardContentProps } from "@mui/material/CardContent/CardContent";

type SecondaryCardProps = PropsWithChildren & {
  title?: ReactNode;
  removeCardContentPadding?: boolean;
  cardProps?: CardProps;
  cardContentProps?: CardContentProps;
  cardHeaderProps?: CardHeaderProps;
};
const SecondaryCard = ({ title, children, removeCardContentPadding = false, cardProps, cardContentProps, cardHeaderProps }: SecondaryCardProps) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant='outlined' sx={{ backgroundColor: "rgba(233, 233, 233, 0.2)" }} {...cardProps}>
        {title && <CardHeader {...cardHeaderProps} title={title} />}
        <CardContent sx={removeCardContentPadding ? { padding: "0px" } : {}} {...cardContentProps}>
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SecondaryCard;
