import { ApiURLs, httpClient } from "api/core";
import {
  ArchivedReportForListDto, ArchivedReportForReadDto, PaginatedDataTable
} from "types";

const URL = ApiURLs.archivedReportManagement;
async function getArchivedReport(archivedReportId: string) {
  return await httpClient.get<ArchivedReportForReadDto>(`${URL}/${archivedReportId}`);
}
async function getArchivedReports(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<ArchivedReportForListDto>>(`${URL}?${queryParams}`);
}

async function deleteArchivedReport(archivedReportId: string) {
  return await httpClient.delete(`${URL}/${archivedReportId}`);
}

export const ArchivedReportService = {
  profile: {
    request: getArchivedReport,
    queryKey: "getArchivedReport"
  },
  list: {
    request: getArchivedReports,
    queryKey: "getArchivedReports"
  },
  delete: {
    request: deleteArchivedReport,
    mutationKey: "delete-archived-report"
  }
};