import { styled } from "@mui/system";
import { Card, CardContent, Stack, Typography } from "@mui/material";

const StyledCard = styled(Card)(() => ({
  backgroundColor: "#fff",
  width: "100%",
  marginBottom: "0",
  border: "none",
  borderRadius: "0",
  borderTop: "1px solid #e9e9e9",
  "&:last-child": {
    borderBottom: "none",
    borderRadius: "0 0 16px 16px"
  },
  "&:first-of-type": {
    borderRadius: "16px 16px 0 0",
    borderTop: "none"
  }
}));

const NotificationCardContent = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  padding: "16px"
});

const CardHeader = styled(Stack)({
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "8px"
});

const IconText = styled(Typography)({
  flex: 1,
  fontWeight: 600,
  fontSize: "14px",
  color: "#464646",
  cursor: "pointer"
});

const NotificationText = styled(Typography)({
  fontSize: "13px",
  color: "#6b6b6b",
  marginBottom: "8px"
});

const FooterText = styled(Typography)({
  fontSize: "12px",
  color: "#b5b5b5",
  fontWeight: 400
});

export { StyledCard, NotificationCardContent, CardHeader, IconText, NotificationText, FooterText };
