import { useMutation } from "@tanstack/react-query";
import { NotificationsService } from "api";
import { useIsLoadingMutation } from "hooks/utils";

export const useGetAppointmentDataByNotificationMutation = () => {
  const mutation = useMutation({
    mutationKey: [NotificationsService.getAppointmentData.queryKey],
    mutationFn: (notificationId: string) => {
      return NotificationsService.getAppointmentData.request(notificationId);
    }
  });

  const onGetAppointmentDataByNotification = (notificationId: string) => {
    return mutation.mutateAsync(notificationId);
  };

  const { isLoading } = useIsLoadingMutation(NotificationsService.getAppointmentData.queryKey);

  return {
    ...mutation,
    isLoading,
    onGetAppointmentDataByNotification
  };
};
