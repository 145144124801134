import { createTheme, ThemeOptions } from "@mui/material/styles";
import componentStyleOverrides from "themes/ComponentStyleOverrides";
import themePalette from "./Palette";
import themeTypography from "./Typography";
import { PresetColors } from "types";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { setSpectraTheme } from "themes/theme/SpectraTheme";
import { APP_CONFIGURATIONS } from "config";

function getThemeVariables(): { [key: string]: string } {
  const rootStyles = getComputedStyle(document.documentElement);

  return Object.fromEntries(
    Array.from(document.styleSheets)
      .flatMap((styleSheet: CSSStyleSheet) => {
        try {
          return Array.from(styleSheet.cssRules);
        } catch (error) {
          return [];
        }
      })
      .filter((cssRule): cssRule is CSSStyleRule => cssRule instanceof CSSStyleRule)
      .filter((cssRule: CSSStyleRule) => cssRule.selectorText === ":root")
      .flatMap((cssRule: CSSStyleRule) => Array.from(cssRule.style))
      .filter((style: string) => style.startsWith("--"))
      .map((variable: string) => [variable, rootStyles.getPropertyValue(variable).trim()])
  );
}

function cssVarsToCamelCase(obj: { [key: string]: string }): { [key: string]: string } {
  const toCamelCase = (str: string) => {
    return str
      .replace(/^-+/, "")
      .replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
      .replace(/-(\d+)/g, "$1");
  };

  const camelCasedObj: { [key: string]: string } = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = toCamelCase(key);
      camelCasedObj[camelCaseKey] = obj[key];
    }
  }

  return camelCasedObj;
}

export const theme = () => {
  if (APP_CONFIGURATIONS.REACT_APP_TENANT_NAME === "Spectrum") {
    setSpectraTheme();
  }

  const c = cssVarsToCamelCase(getThemeVariables());

  const themeOption: PresetColors = {
    ...(c as unknown as PresetColors)
  };

  const themeOptions: ThemeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px"
        }
      }
    },
    typography: themeTypography(themeOption) as TypographyOptions
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;
