import { MRT_ColumnDef } from "material-react-table";
import { AppointmentFinanceForListDto, EAppointmentType, EDuplicatePaymentTransactionPaidStatus } from "types";
import { DateUtils, FormatUtils } from "utils";
import { useUsersLookupQuery, usePermissions } from "hooks";
import { AppPermissions, UserRoles } from "app-constants";
import { Chip } from "@mui/material";
import APPOINTMENT_TYPES from "app-constants/AppointmentTypes";

export const useFinancialsDetailsColumns = () => {
  const { data: doctorsLookup } = useUsersLookupQuery(UserRoles.Doctor);
  const { hasPermission } = usePermissions();

  const billerView = hasPermission([AppPermissions.BillingManagement.Actions.BillerView]);
  const financeView = hasPermission([AppPermissions.BillingManagement.Actions.FinanceView]);
  const doctorView = hasPermission([AppPermissions.BillingManagement.Actions.DoctorView]);
  const fullView = hasPermission([AppPermissions.BillingManagement.Actions.FullView]);

  const fullViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: APPOINTMENT_TYPES.DATATABLE_LIST,
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Number of Payments",
      accessorKey: "numberOfPayments",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Deposit Date",
      accessorKey: "depositDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.depositDate ?? "");
      }
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return <Chip label={FormatUtils.fromPascalToNormal(row.original.paidStatus ?? "")} size='small' color='default' />;
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    },
    {
      header: "Doctor Paid Date",
      accessorKey: "doctorPaidDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.doctorPaidDate ?? "");
      }
    }
  ];
  const billerViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: APPOINTMENT_TYPES.DATATABLE_LIST,
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    }
  ];
  const financeViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Doctor Name",
      accessorKey: "doctorName",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "assignedDoctorIdsFilter",
      filterVariant: "multi-select",
      filterSelectOptions: FormatUtils.formatDropdownOptions("id", "name", doctorsLookup)
    },
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: APPOINTMENT_TYPES.DATATABLE_LIST,
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Expected Amount",
      accessorKey: "expectedAmount",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return FormatUtils.formatMoneyAdvanced(row.original?.expectedAmount ?? 0);
      }
    },
    {
      header: "Number of Payments",
      accessorKey: "numberOfPayments",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return <Chip label={FormatUtils.fromPascalToNormal(row.original.paidStatus ?? "")} size='small' color='default' />;
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    }
  ];
  const doctorViewColumns: Array<MRT_ColumnDef<AppointmentFinanceForListDto>> = [
    {
      header: "Patient Name",
      accessorKey: "patientName",
      enableColumnFilterModes: false,
      enableColumnFilter: false
    },
    {
      header: "Received Date",
      accessorKey: "receivedDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.receivedDate ?? "");
      }
    },
    {
      header: "Date of Service",
      accessorKey: "dateOfService",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.dateOfService ?? "");
      }
    },
    {
      header: "Appointment Type",
      accessorKey: "appointmentType",
      enableColumnFilterModes: false,
      enableColumnFilter: true,
      id: "typesFilter",
      filterVariant: "multi-select",
      filterSelectOptions: APPOINTMENT_TYPES.DATATABLE_LIST,
      Cell: ({ row }) => {
        switch (row.original.appointmentType) {
          case EAppointmentType.InitialEvaluation:
            return "Initial Evaluation";
          case EAppointmentType.ReEvaluation:
            return "Re Evaluation";
          case EAppointmentType.Supplemental:
            return "Supplemental";
          case EAppointmentType.Deposition:
            return "Deposition";
        }
      }
    },
    {
      header: "Financial Status",
      accessorKey: "paidStatus",
      enableColumnFilterModes: false,
      Cell: ({ row }) => {
        return <Chip label={FormatUtils.fromPascalToNormal(row.original.paidStatus ?? "")} size='small' color='default' />;
      },
      id: "statusFilter",
      filterVariant: "multi-select",
      enableColumnFilter: true,
      filterSelectOptions: [
        {
          value: EDuplicatePaymentTransactionPaidStatus.NotPaid,
          text: "Unpaid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.FullyPaid,
          text: "Paid"
        },
        {
          value: EDuplicatePaymentTransactionPaidStatus.PartiallyPaid,
          text: "Partially Paid"
        }
      ]
    },
    {
      header: "Doctor Paid Date",
      accessorKey: "doctorPaidDate",
      enableColumnFilterModes: false,
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return DateUtils.formatDate(row.original?.doctorPaidDate ?? "");
      }
    }
  ];

  if (fullView) {
    return {
      columns: fullViewColumns
    };
  }

  if (billerView) {
    return {
      columns: billerViewColumns
    };
  }

  if (financeView) {
    return {
      columns: financeViewColumns
    };
  }

  if (doctorView) {
    return {
      columns: doctorViewColumns
    };
  }

  return {
    columns: []
  };
};
