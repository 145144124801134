import { useEffect, useState } from "react";
import { useProfileQuery } from "hooks/users";
import { useStore } from "stores";
import { RoleWithClaimsForReadDto } from "types";
import { UserRoles } from "app-constants";

export const useAuthorization = (requiredPermissions: string[], roles?: UserRoles[]) => {
  const { userPermissionsStore } = useStore();
  const { permissions, setPermissions } = userPermissionsStore;

  const { onFetchProfile, data } = useProfileQuery();
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (permissions.length === 0) {
      onFetchProfile()
        .then((response) => {
          setPermissions(response.data?.roles ?? []);
          const userPermissions = response.data?.roles ?? [];
          const access = hasPermission(requiredPermissions, userPermissions);
          setHasAccess(access);
        })
        .catch(console.error)
        .finally(() => setLoading(false));

    } else {
      const access = hasPermission(requiredPermissions, permissions);
      setHasAccess(access);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [permissions.length, requiredPermissions]);

  function hasPermission(permissionToCheck: string[], userPermissions: RoleWithClaimsForReadDto[]) {
    for (const role of userPermissions ?? []) {
      const claims = role.claims;
      for (const claim of claims) {
        if (permissionToCheck.includes(claim.claimValue)) {
          return true;
        }
      }
    }
    return false;
  }
  const hasRoles = data?.roles?.some(role => roles?.includes(role.name));

  return {
    hasAccess,
    loading,
    roles: data?.roles,
    hasRoles
  };
};
