import { Box } from "@mui/material";
import { LogoSectionBrowser } from "components/logo";
import { ProfileSection } from "components/layout/profile";
import { observer } from "mobx-react-lite";
import { Notifications } from "components/layout/notifications";
import { AuthorizationWrapper } from "components/permissions";
import { AppPermissions } from "app-constants";

function Header() {
  return (
    <>
      <LogoSectionBrowser />

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />
      <AuthorizationWrapper permissions={[AppPermissions.AppNotificationManagement.Actions.View]}>
        <Notifications />
      </AuthorizationWrapper>
      <ProfileSection />
    </>
  );
}

export default observer(Header);
