import React from "react";
import { Checkbox, List, ListItemButton, ListItemText } from "@mui/material";
import { MRT_Column, MRT_TableInstance } from "material-react-table";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

const StyledBadgeFilter = styled("span")`
  min-width: 20px;
  min-height: 20px;
  margin: 0 57px 0 8px;
  background-color: var(--secondary-main);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
type CheckboxListProps<TData extends Record<string, any> = {}> = {
  table: MRT_TableInstance<TData>;
  handleSelect(column: MRT_Column<TData>, value: string): void;
  selected?: Map<string, string[]>;
};
function FilterDetails<T extends Record<string, any> = {}>({ table, handleSelect, selected }: CheckboxListProps<T>) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const onExpandHandler = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {table.getAllColumns().map((column, index) => {
        const currentColumnFilterValues = selected?.get(column.id) ?? [];
        if (!column.getCanFilter()) return null;
        return (
          <Accordion expanded={expanded === column.id} key={`${column.id}-${index}`} onChange={onExpandHandler(column.id)}>
            <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls='panel1a-content' id={`panel1a-header-${column.id}`}>
              <Typography>{column.columnDef.header}</Typography>
              {currentColumnFilterValues.length > 0 && <StyledBadgeFilter>{currentColumnFilterValues.length}</StyledBadgeFilter>}
            </AccordionSummary>
            <AccordionDetails sx={{ maxHeight: "150px", overflowY: "auto", maxWidth: 340, minWidth: 202 }}>
              <List dense component='div' role='list'>
                {column.columnDef.filterSelectOptions?.map((option, index) => {
                  const labelId = typeof option === "string" ? option : option.text;
                  const value = typeof option === "string" ? option : option.value;
                  return (
                    <ListItemButton
                      key={`${labelId}-${index}`}
                      role='listitem'
                      divider
                      sx={{ width: "100%", color: "#6d6d6d", borderRadius: 0 }}
                      onClick={() => handleSelect(column, value)}
                      dense
                    >
                      <ListItemText id={labelId} primary={labelId} />
                      <Checkbox
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId
                        }}
                        checked={currentColumnFilterValues?.includes(value)}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
}

export default FilterDetails;
