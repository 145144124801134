export function setSpectraTheme() {
  const root = document.documentElement;
  const primary = {
    100: "#fff7ed",
    200: "#ffedd4",
    300: "#ffbb70",
    400: "#ff9337",
    500: "#7f2d0f",
    main: "#ff7b1c"
  };
  const secondary = {
    100: "#f0fdfc",
    200: "#ccfbf9",
    300: "#5fe8e9",
    400: "#15aeb7",
    500: "#12545d",
    main: "#0d7f89"
  };

  const grey = {
    50: "#f4f4f4",
    100: "#dfdfdf",
    200: "#d4d4d4",
    300: "#b5b5b5",
    400: "#909090",
    500: "#383838",
    main: "#464646"
  };

  const error = {
    main: "#FF0000"
  };

  const warning = {
    main: "#FFC300"
  };

  const success = {
    main: "#19A251"
  };

  const info = {
    main: "#0072f3"
  };

  root.style.setProperty("--primary-main", primary.main);
  root.style.setProperty("--primary-100", primary[100]);
  root.style.setProperty("--primary-200", primary[200]);
  root.style.setProperty("--primary-300", primary[300]);
  root.style.setProperty("--primary-400", primary[400]);
  root.style.setProperty("--primary-500", primary[500]);
  root.style.setProperty("--background-main", primary[100]);
  root.style.setProperty("--secondary-main", secondary.main);
  root.style.setProperty("--secondary-100", secondary[100]);
  root.style.setProperty("--secondary-200", secondary[200]);
  root.style.setProperty("--secondary-300", secondary[300]);
  root.style.setProperty("--secondary-400", secondary[400]);
  root.style.setProperty("--secondary-500", secondary[500]);
  root.style.setProperty("--gray-50", grey[500]);
  root.style.setProperty("--gray-100", grey[100]);
  root.style.setProperty("--gray-200", grey[200]);
  root.style.setProperty("--gray-300", grey[300]);
  root.style.setProperty("--gray-400", grey[400]);
  root.style.setProperty("--gray-500", grey[500]);
  root.style.setProperty("--gray-main", grey.main);
  root.style.setProperty("--status-danger", error.main);
  root.style.setProperty("--status-warning-main", warning.main);
  root.style.setProperty("--status-success", success.main);
  root.style.setProperty("--link-main", info.main);
  root.style.setProperty("--bg-data-table", primary[200]);
}
