import { BillingManagementService } from "api";
import { usePaginatedDataTableQuery } from "hooks/index";

export const useAgingReportListQuery = () => {
  const query = usePaginatedDataTableQuery({
    queryFn: BillingManagementService.doctorAgingReport.request,
    queryKey: BillingManagementService.doctorAgingReport.queryKey
  });

  const onFetchData = async () => {
    await query.refetch();
  };

  return {
    onFetchData,
    ...query
  };
};
