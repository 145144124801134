import { Menu, MenuProps } from "@mui/material";

type PrimeMenuProps = MenuProps & {};
function PrimeMenu(props: PrimeMenuProps) {
  return (
    <Menu
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32
            }
          }
        }
      }}
      keepMounted
      {...props}
    />
  );
}

export default PrimeMenu;
