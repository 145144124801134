import React, { useState } from "react";
import { ListItemButton, ListItemIcon, ListItemText, Stack, Typography, Collapse, SxProps } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { MenuItem } from "types";
import { AppRoutes } from "router/AppRoutes";
import { useIsProductionReadyFeature } from "hooks";
import { AuthorizationWrapper } from "components/permissions";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Theme } from "@mui/material/styles/createTheme";

const listItemButtonStyles: SxProps<Theme> = {
  mb: 1,
  "&:hover": {
    backgroundColor: "var(--light-gray-transparent)",
    borderLeft: "4px solid #fff",
    color: "#fff",
    "& .MuiListItemIcon-root": {
      color: "var(--primary-main)"
    },
    "& .MuiListItemText-root": {
      color: "var(--primary-main)"
    },
    ".sidebar-icon": {
      fill: "#fff"
    }
  },
  "&.Mui-selected": {
    backgroundColor: "var(--light-gray-transparent)",
    borderLeft: "4px solid #fff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "var(--light-gray-transparent)"
    },
    "& .MuiListItemIcon-root": {
      color: "var(--primary-main)"
    },
    "& .MuiListItemText-root": {
      color: "var(--primary-main)"
    },
    ".sidebar-icon": {
      fill: "#fff"
    }
  }
};

type MenuItemProps = {
  menuItem: MenuItem;
};

function MenuListItem({ menuItem }: MenuItemProps) {
  const location = useLocation();
  const { to, icon, label, children = [] } = menuItem;

  const isActive = (to === AppRoutes.app && location.pathname === to) || (to !== AppRoutes.app && location.pathname.startsWith(to));

  const [open, setOpen] = useState(isActive);

  const isProductionReady = useIsProductionReadyFeature(to);

  const handleToggle = () => {
    setOpen(!open);
  };

  if (!isProductionReady) {
    return null;
  }

  return (
    <AuthorizationWrapper permissions={menuItem.permissions} roles={menuItem.roles}>
      <ListItemButton
        key={label}
        onClick={children.length ? handleToggle : undefined}
        component={Link}
        to={!children.length ? to : "#"}
        selected={!children.length ? isActive : false}
      >
        <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
        <ListItemText>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='body2'>{label}</Typography>
            {children.length > 0 && (open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
          </Stack>
        </ListItemText>
      </ListItemButton>
      {children.length > 0 && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          {children.map(child => (
            <AuthorizationWrapper key={child.label} permissions={child.permissions} roles={child.roles}>
              <ListItemButton component={Link} to={child.to} selected={location.pathname.startsWith(child.to)} sx={listItemButtonStyles}>
                <ListItemText>
                  <Typography sx={{ color: "#fff", pl: "36px" }} variant='body2'>
                    {child.label}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </AuthorizationWrapper>
          ))}
        </Collapse>
      )}
    </AuthorizationWrapper>
  );
}

export default MenuListItem;
