import { useMutation } from "@tanstack/react-query";
import { TaskManagementService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ErrorsUtils, SnackbarUtils } from "utils";
import { ApiError, ChangeTaskStatusDto } from "types";

export const useChangeTaskStatusMutation = () => {
  const mutation = useMutation({
    mutationKey: [TaskManagementService.changeTaskStatus.mutationKey],
    mutationFn: TaskManagementService.changeTaskStatus.request,
    onSuccess: () => {
      SnackbarUtils.success("Task status changed successfully");
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const changeTaskStatus = (data: ChangeTaskStatusDto) => {
    return mutation.mutateAsync(data);
  };

  const { isLoading } = useIsLoadingMutation(TaskManagementService.changeTaskStatus.mutationKey);

  return {
    ...mutation,
    changeTaskStatus,
    isLoading
  };
};
