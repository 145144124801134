import { lazy } from "react";
import { WithLoadable } from "components/app-loader";
import { Navigate, RouteObject } from "react-router-dom";
import { AppRoutes } from "router/AppRoutes";
import { MainLayout } from "layout";
import AuthGuard from "router/AuthGuard";
import { AppointmentFormsRoutes, AppointmentsRoutes } from "router/AppointmentsRoutes";
import { DoctorFormsRoutes, DoctorRoutes } from "router/DoctorRoutes";
import { ActiveReportFormsRoutes, ActiveReportRoutes } from "router/ActiveReportRoutes";
import { UserFormsRoutes, UsersRoutes } from "router/UsersRoutes";
import { ArchivedReportsRoutes } from "router/ArchivedReportsRoutes";
import { PatientFormsRoutes, PatientsRoutes } from "router/PatientsRoutes";
import { ManagementFormsRoutes, ManagementRoutes } from "router/ManagementRoutes";
import { BillingsFormsRoutes, BillingsRoutes } from "router/BillingsRoutes";
import { DuplicatePaymentsFormsRoutes, DuplicatePaymentsRoutes } from "router/DuplicatePaymentsRoutes";
import { NotificationsRoutes } from "router/NotificationsRoutes";

const NotFoundPage = WithLoadable(lazy(() => import("pages/not-found/NotFoundPage")));
const ForbiddenPage = WithLoadable(lazy(() => import("pages/forbidden/ForbiddenPage")));

const MainRoutes: RouteObject = {
  path: AppRoutes.app,
  children: [
    {
      element: <AuthGuard requiredAuth />,
      children: [
        {
          element: <MainLayout />,
          children: [
            {
              path: "/",
              element: <Navigate to={AppRoutes.patients.root} replace />
            },
            AppointmentsRoutes,
            DoctorRoutes,
            ActiveReportRoutes,
            ArchivedReportsRoutes,
            UsersRoutes,
            PatientsRoutes,
            ManagementRoutes,
            BillingsRoutes,
            NotificationsRoutes,
            DuplicatePaymentsRoutes,
            {
              path: AppRoutes.notFound,
              element: <NotFoundPage />
            },
            {
              path: AppRoutes.forbidden,
              element: <ForbiddenPage />
            },
            {
              path: "*",
              element: <Navigate replace to={AppRoutes.notFound} />
            }
          ]
        },

        // these routes for FormLayout
        DoctorFormsRoutes,
        ActiveReportFormsRoutes,
        AppointmentFormsRoutes,
        UserFormsRoutes,
        PatientFormsRoutes,
        ManagementFormsRoutes,
        BillingsFormsRoutes,
        DuplicatePaymentsFormsRoutes
      ]
    }
  ]
};
export default MainRoutes;
