import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ActiveReportService } from "api";

export const useActiveReportProfileQuery = (autoFetch = true) => {
  const { activeReportId } = useParams<{ activeReportId: string }>();
  const activeReportProfileQuery = useQuery({
    queryKey: [ActiveReportService.profile.queryKey, activeReportId],
    queryFn: () => ActiveReportService.profile.request(activeReportId!),
    enabled: autoFetch,
    select: data => {
      return {
        ...data,
        editorsIds: data.medLegalConsultants.map(m => m.id),
        historiansIds: data.historians.map(historian => historian.id),
        schedulersIds: data.schedulers.map(scheduler => scheduler.id),
        transcribersIds: data.transcribers.map(transcriber => transcriber.id),
        medLegalConsultantsIds: data.medLegalConsultants.map(medLegalConsultant => medLegalConsultant.id),
        recordManagementsIds: data.recordManagements.map(recordManagement => recordManagement.id)
      };
    }
  });

  const onFetchActiveReportProfile = () => {
    return activeReportProfileQuery.refetch();
  };

  return {
    ...activeReportProfileQuery,
    onFetchActiveReportProfile
  };
};