import React, { useState } from "react";
import { StyledToggleButton, StyledToggleButtonGroup } from "./ToggleSwitchButtons.styled";

type Filter = "all" | "unread";
type ToggleSwitchButtonsProps = {
  onFilteredBy(value: Filter): void;
  showUnread: boolean;
};
function ToggleSwitchButtons({ onFilteredBy, showUnread }: ToggleSwitchButtonsProps) {
  const [filterBy, setFilterBy] = useState<Filter>(showUnread ? "unread" : "all");

  const onChangeFilterHandler = (_: React.MouseEvent<HTMLElement>, newAlignment: Filter) => {
    if (!newAlignment) return;
    setFilterBy(newAlignment);
    onFilteredBy(newAlignment);
  };

  return (
    <StyledToggleButtonGroup value={filterBy} exclusive onChange={onChangeFilterHandler}>
      <StyledToggleButton value='all' aria-label='all'>
        All
      </StyledToggleButton>
      <StyledToggleButton value='unread' aria-label='unread'>
        Unread
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
}

export default ToggleSwitchButtons;
