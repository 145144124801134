import { PresetColors } from "types";
import { Components } from "@mui/material";
import { Theme as SystemTheme } from "@mui/system/createTheme/createTheme";

export default function componentStyleOverrides(theme: PresetColors): Components<SystemTheme> {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          borderRadius: "4px",
          "&.MuiButton-containedSecondary": {
            color: "#fff"
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: "none"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.grayMain,
          padding: "24px"
        },
        title: {
          fontSize: "1.125rem"
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px"
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px"
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          width: "240px",
          borderRadius: "4px",
          fontWeight: 300,
          transition: "all 0.3s ease-out",
          "&.Mui-selected": {
            backgroundColor: theme.silver100,
            "&:hover": {
              backgroundColor: theme.silver100
            },
            "& .MuiListItemIcon-root": {
              color: theme.primaryMain
            },
            "& .MuiListItemText-root": {
              color: theme.primaryMain
            },
            ".sidebar-icon": {
              fill: theme.primaryMain
            }
          },
          "&:hover": {
            backgroundColor: theme.silver100,
            color: theme.primaryMain,
            "& .MuiListItemIcon-root": {
              color: theme.primaryMain
            },
            "& .MuiListItemText-root": {
              color: theme.primaryMain
            },
            ".sidebar-icon": {
              fill: theme.primaryMain
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "36px"
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.silver300,
          opacity: 1
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit"
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.gray500
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff"
        }
      },
      defaultProps: {
        size: "small",
        fullWidth: true,
        color: "primary"
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: theme.secondaryCardBackground,
          borderRadius: "6px",
          border: `solid 1px ${theme.silver300}`,
          marginBottom: "14px"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "6px"
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "6px"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "#fff"
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "::before": {
            display: "none"
          }
        }
      }
    }
  };
}
