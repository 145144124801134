import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AppointmentService } from "api";
import { AppointmentForReadDto, BackOfficeTeamKeySelector, UserForMinimalReadDto } from "types";
import { DateUtils } from "utils";

const extractTeamIds = (teamMembers: UserForMinimalReadDto[]): string[] => {
  return teamMembers.map(member => member.id);
};

const mapTeamRolesToIds = (response: AppointmentForReadDto, teamRoles: BackOfficeTeamKeySelector[]) => {
  const teamDetails = new Map<string, string[]>();

  teamRoles.forEach(role => {
    const teamMembers = response[role as keyof AppointmentForReadDto] as UserForMinimalReadDto[];
    if (teamMembers) {
      teamDetails.set(`${role}Ids`, extractTeamIds(teamMembers));
    }
  });

  return Object.fromEntries(teamDetails);
};

export const useAppointmentProfileQuery = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>();

  const appointmentProfileQuery = useQuery({
    queryKey: [AppointmentService.details.queryKey, appointmentId],
    queryFn: () => AppointmentService.details.request(appointmentId!),
    select: (response) => {
      const doctorAvailabilityTimeRange = response?.doctorAvailabilityTimeRange;
      const appointmentTime = `${DateUtils.formatToTime(doctorAvailabilityTimeRange?.startTime)} - ${DateUtils.formatToTime(doctorAvailabilityTimeRange?.endTime)}`;
      const backOfficeTeamRoles: BackOfficeTeamKeySelector[] = [
        "schedulers",
        "medLegalConsultants",
        "historians",
        "billers",
        "transcribers",
        "recordManagements"
      ];

      const teamDetailsObject = mapTeamRolesToIds(response, backOfficeTeamRoles);

      return {
        ...response,
        ...teamDetailsObject,
        locationId: response?.location?.id,
        appointmentTime
      };
    }
  });

  return {
    ...appointmentProfileQuery
  };
};
