import { makeAutoObservable } from "mobx";
import { UserForReadDto } from "types";

export default class CommonStore {
  isOpenNavbar = true;
  userDetails: UserForReadDto | null = null;
  constructor() {
    makeAutoObservable(this);
  }

  toggleNavbar = () => {
    this.isOpenNavbar = !this.isOpenNavbar;
  };

  setUserDetails = (userDetails: UserForReadDto | null) => {
    this.userDetails = userDetails;
  };
}
