import { useInfiniteQuery } from "@tanstack/react-query";
import { LookupService } from "api";
import { ApiVersions } from "types";

type TaskUsersLookupInfiniteQueryParams = {
  search?: string;
  selectedRolesIds?: string[];
  assignedUserId?: string | null;
};
export const useTaskUsersLookupInfiniteQuery = ({ search, selectedRolesIds, assignedUserId }: TaskUsersLookupInfiniteQueryParams) => {
  const query = useInfiniteQuery(
    [LookupService.taskUsers.queryKey, search, selectedRolesIds?.join(","), assignedUserId],
    ({ pageParam = 1 }) => {
      const queryParam = new URLSearchParams();
      queryParam.append("searchTerm", search ?? "");
      queryParam.append("pageNumber", (+pageParam).toString());
      if(assignedUserId) {
        queryParam.append("selectedItemsIds", assignedUserId);
      }
      queryParam.append("pageSize", "20");
      if (selectedRolesIds) {
        selectedRolesIds.forEach(roleId => {
          queryParam.append("roleIds", roleId);
        });
      }
      queryParam.append("api-version", ApiVersions.V2.toString());
      return LookupService.taskUsers.request(queryParam.toString());
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.data.length === 0) return undefined;
        return pages.length + 1;
      }
    }
  );

  return {
    ...query
  };
};
