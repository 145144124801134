import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Cancel from "@mui/icons-material/Cancel";

type NoRecordMessageProps = {
  message: string;
};
function NoRecordMessage({ message }: NoRecordMessageProps) {
  return (
    <Grid item xs={12}>
      <Stack direction='row' alignItems='center' gap={1}>
        <Cancel sx={{ color: "var(--gray-50)" }} />
        <Typography color={"var(--gray-main)"}>{message}</Typography>
      </Stack>
    </Grid>
  );
}

export default NoRecordMessage;
