import { ApiURLs, httpClient } from "api/core";
import { ChangeTaskStatusDto } from "types";
const URL = ApiURLs.taskManagement;

async function assignUserToTask(appointmentTaskId: string, userId: string) {
  return await httpClient.put(`${URL}/${appointmentTaskId}/assign-user-to-task`, {
    appointmentTaskId,
    userId
  });
}
async function unAssignUserFromTask(appointmentTaskId: string) {
  return await httpClient.put(`${URL}/${appointmentTaskId}/unassign-user-from-task`, {
    appointmentTaskId
  });
}

async function changeTaskStatus(data: ChangeTaskStatusDto) {
  return await httpClient.put(`${URL}/${data.appointmentTaskId}/change-task-status`, {
    appointmentTaskId: data.appointmentTaskId,
    newTaskStatus: data.newTaskStatus
  });
}
export const TaskManagementService = {
  assignUserToTask: {
    request: assignUserToTask,
    mutationKey: "assignUserToTask"
  },
  unAssignUserFromTask: {
    request: unAssignUserFromTask,
    mutationKey: "unAssignUserFromTask"
  },
  changeTaskStatus: {
    request: changeTaskStatus,
    mutationKey: "changeTaskStatus"
  }
};
