import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationsService } from "api";
import { useIsLoadingMutation } from "hooks/utils";
import { ApiError } from "types";
import { ErrorsUtils, SnackbarUtils } from "utils";

export const useMarkAllNotificationsAsReadMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [NotificationsService.markAllAsRead.mutationKey],
    mutationFn: NotificationsService.markAllAsRead.request,
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [NotificationsService.notifications.queryKey]
      });
      SnackbarUtils.success("All notifications marked as read successfully");
    }
  });

  const { isLoading } = useIsLoadingMutation(NotificationsService.markAllAsRead.mutationKey);

  const onMarkAllNotificationsAsRead = () => {
    return mutation.mutateAsync();
  };

  return {
    ...mutation,
    onMarkAllNotificationsAsRead,
    isLoading
  };
};
