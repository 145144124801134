import { ApiURLs, httpClient } from "api/core";
import { ApiVersions, PaginatedDataTable, PatientFileForListDto } from "types";
import {
  PatientFileForReadDto,
  PatientFileForCreateUpdateDto
} from "types/api-v2";

const URL = ApiURLs.patientFiles;

async function getPatients(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<PatientFileForListDto>>(
    `${URL}?${queryParams}`
  );
}

async function createPatient(
  patient: PatientFileForCreateUpdateDto,
  apiVersion: ApiVersions
) {
  return await httpClient.post<{ patientFileId: string }>(
    `${URL}?api-version=${apiVersion}`,
    patient
  );
}

async function getPatientDetails(patientId: string, apiVersion: ApiVersions) {
  return await httpClient.get<PatientFileForReadDto>(
    `${URL}/${patientId}?api-version=${apiVersion}`
  );
}

async function updatePatient(
  patientId: string,
  patient: PatientFileForCreateUpdateDto,
  apiVersion: ApiVersions
) {
  return await httpClient.put<void>(
    `${URL}/${patientId}?api-version=${apiVersion}`,
    patient
  );
}

async function deletePatientFile(patientFileId: string) {
  return await httpClient.delete<void>(`${URL}/${patientFileId}`);
}

export const PatientService = {
  list: {
    request: getPatients,
    queryKey: "patientsList"
  },
  create: {
    request: createPatient,
    queryKey: "createPatient"
  },
  update: {
    request: updatePatient,
    queryKey: "updatePatient"
  },
  details: {
    request: getPatientDetails,
    queryKey: "patientDetails"
  },
  delete: {
    request: deletePatientFile,
    mutationKey: "deletePatientFile"
  }
};
