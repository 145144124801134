// firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-gfaYQ3PmOOrccOEP-wFXtTr8c6EtetM",
  authDomain: "qlinica-73ef2.firebaseapp.com",
  projectId: "qlinica-73ef2",
  storageBucket: "qlinica-73ef2.appspot.com",
  messagingSenderId: "227609943816",
  appId: "1:227609943816:web:d25489f255b81708b8d6bd",
  measurementId: "G-3VRNM3335E"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  return await getToken(messaging, { vapidKey: "BGSbCTm0EzXMNwoyIGIhwj0YPRIajbWl30WYf88bcci07MIEkmjXsU8Hjf87NwekmiSeRV-rCj6zwlFumEbRCgQ" });
};
