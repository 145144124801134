import { AppointmentForCalendarDto, AppointmentScheduleDetail, SlotTimeRange } from "types";
import { AppointmentForCreateUpdateFormContext } from "types/AppointmentForCreateUpdateFormContext";
import moment from "moment/moment";
import { DATE_TIME_FORMAT } from "app-constants";

export default class AppointmentUtils {
  static checkAppointmentConflict(selectedScheduleDetail: AppointmentScheduleDetail, doctorAppointments: AppointmentForCalendarDto[]) {
    const { date, duration } = selectedScheduleDetail;
    // Convert selected start and end times to Date objects
    const selectedStartTime = new Date(`${date}T${duration?.startTime}`);
    const selectedEndTime = new Date(`${date}T${duration?.endTime}`);

    for (const appointment of doctorAppointments) {
      if (appointment.scheduleDetail.date === date) {
        const scheduleDetail = appointment.scheduleDetail;

        // Convert doctor appointment start and end times to Date objects
        const doctorStartTime = new Date(`${scheduleDetail.date}T${scheduleDetail?.duration?.startTime}`);
        const doctorEndTime = new Date(`${scheduleDetail.date}T${scheduleDetail?.duration?.endTime}`);

        // Check for conflicts
        if (
          (selectedStartTime >= doctorStartTime && selectedStartTime < doctorEndTime) ||
          (selectedEndTime > doctorStartTime && selectedEndTime <= doctorEndTime) ||
          (selectedStartTime <= doctorStartTime && selectedEndTime >= doctorEndTime)
        ) {
          // Conflict found
          return true;
        }
      }
    }

    // No conflicts found
    return false;
  }

  static isConflictingAppointment(formContext: AppointmentForCreateUpdateFormContext) {
    if (!formContext) return false;
    const scheduleDetail = formContext.scheduleDetail;
    const appointments = formContext.appointments;
    return AppointmentUtils.checkAppointmentConflict(scheduleDetail, appointments ?? []);
  }

  static isTimeInPast(date: string, time: string): boolean {
    if (!date || !time) return false;

    const currentDateTime = moment();
    const providedDateTime = moment(`${date} ${time}`, `${DATE_TIME_FORMAT.DATE} ${DATE_TIME_FORMAT.FULL_TIME}`, true);

    if (!providedDateTime.isValid()) {
      throw new Error("Invalid date or time format.");
    }

    // Check if the provided date is in the future
    const isDateInFuture = providedDateTime.isAfter(currentDateTime, "seconds");

    if (isDateInFuture) {
      // If the date is in the future, check if the time is after the current time
      const isTimeInFuture = providedDateTime.isAfter(currentDateTime, "seconds");
      return !isTimeInFuture;
    } else {
      // If the date is in the past or the current date, return true
      return true;
    }
  }

  static isStartTimeInRange(startTime: string, range: SlotTimeRange) {
    const rangeStartTimeMoment = moment(range.startTime, DATE_TIME_FORMAT.FULL_TIME);

    const startTimeMoment = moment(startTime, DATE_TIME_FORMAT.FULL_TIME);
    return startTimeMoment.isSameOrAfter(rangeStartTimeMoment);
  }

  static isEndTimeInRange(endTime: string, range: SlotTimeRange) {
    const rangeEndTimeMoment = moment(range.endTime, DATE_TIME_FORMAT.FULL_TIME);
    const endTimeMoment = moment(endTime, DATE_TIME_FORMAT.FULL_TIME);

    return endTimeMoment.isSameOrBefore(rangeEndTimeMoment);
  }

  static startAndEndOfMonth(date?: string): { startOfDay: string; endOfDay: string } {
    const inputDate = date ? moment(date) : moment();

    const startOfMonth = inputDate.clone().startOf("month");

    const endOfMonth = inputDate.clone().endOf("month");

    // Format the dates as strings in the desired format ("YYYY-MM-DD")
    const startOfDay = startOfMonth.format(DATE_TIME_FORMAT.DATE);
    const endOfDay = endOfMonth.format(DATE_TIME_FORMAT.DATE);

    return { startOfDay, endOfDay };
  }

  static getSelectedTimeRange(value: string): SlotTimeRange {
    const START_TIME_INDEX = 1;
    const END_TIME_INDEX = 2;
    const selectedTime = value?.split("_");
    return {
      startTime: selectedTime[START_TIME_INDEX],
      endTime: selectedTime[END_TIME_INDEX]
    };
  }
}
