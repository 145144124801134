import { ApiURLs, httpClient } from "api/core";
import {
  PaginatedDataTable,
  DoctorForCreateUpdateDto,
  DoctorForListDto,
  DoctorForReadDto,
  DoctorSpecialityLocationDto,
  DoctorAssignedToPatienForAppointmentCreateUpdateDto,
  AvailabilitySlots,
  DoctorCalendarForReadDto
} from "types";
import moment from "moment";
import { AppointmentUtils, FileUtils } from "utils";

const URL = ApiURLs.doctorManagement;

async function getDoctors(queryParams: string) {
  return await httpClient.get<PaginatedDataTable<DoctorForListDto>>(`${URL}?${queryParams}`);
}

async function getDoctorDetails(doctorId: string) {
  return await httpClient.get<DoctorForReadDto>(`${URL}/${doctorId}`);
}

async function disableDoctor(doctorId: string) {
  return await httpClient.put<void>(
    `${URL}/${doctorId}/disable`,
    {},
    {
      params: {
        doctorId
      }
    }
  );
}

async function getSpecialitiesLocationsForDoctor(doctorId: string) {
  return await httpClient.get<DoctorSpecialityLocationDto[]>(`${URL}/${doctorId}/specialities-locations`);
}

async function enableDoctor(doctorId: string) {
  return await httpClient.put<void>(
    `${URL}/${doctorId}/enable`,
    {},
    {
      params: {
        doctorId
      }
    }
  );
}

async function uploadSignature(doctorId: string, signature: File[] | null) {
  if (!signature) return;

  const formData = new FormData();
  const fileExtension = FileUtils.getFileExtension(signature[0]);
  const fileName = `${signature[0].name}.${fileExtension}`;
  formData.append("file", signature[0], fileName);
  return await httpClient.put<void>(`${URL}/${doctorId}/signature`, formData);
}

async function getSignature(doctorId: string, signatureId?: string | null) {
  if (!doctorId || !signatureId) return;
  return await httpClient.get<Blob>(`${URL}/${doctorId}/signature/${signatureId}`, {
    responseType: "blob"
  });
}
async function createDoctor(doctor?: DoctorForCreateUpdateDto) {
  if (!doctor) return;
  return await httpClient.post<{ doctorId: string }>(URL, doctor);
}

async function updateDoctor(doctor: DoctorForCreateUpdateDto, doctorId: string) {
  return await httpClient.put<void>(`${URL}/${doctorId}`, doctor);
}

async function deleteDoctor(doctorId: string) {
  return await httpClient.delete<void>(`${URL}/${doctorId}`);
}

async function getAvailabilitySlots(doctorId: string, startDate?: string, endDate?: string) {
  if (!startDate || !endDate) {
    const currentDate = moment();
    const year = currentDate.year();
    const nextTwoYears = year + 2;

    const firstDateOfYear = moment({
      year,
      month: 0,
      day: 1
    });
    startDate = firstDateOfYear.format("MM-DD-YYYY");
    const lastDateOfYear = moment({
      year: nextTwoYears,
      month: 11,
      day: 31
    });
    endDate = lastDateOfYear.format("MM-DD-YYYY");
  }
  return await httpClient.get<AvailabilitySlots>(`${URL}/${doctorId}/availability-slots`, {
    params: {
      startDate,
      endDate
    }
  });
}

async function getDoctorAvailabilitySlotsAndAppointments({ doctorId, currentDate }: { doctorId: string; currentDate: string }) {
  const { startOfDay, endOfDay } = AppointmentUtils.startAndEndOfMonth(currentDate);
  return await httpClient.get<DoctorCalendarForReadDto>(`${URL}/${doctorId}/calendar`, {
    params: {
      startDateFilter: startOfDay,
      endDateFilter: endOfDay
    }
  });
}

async function getDoctorAssignedToPatientFile(patientFileId: string) {
  return await httpClient.get<DoctorAssignedToPatienForAppointmentCreateUpdateDto>(
    `${URL}/doctor-assigned-to-patient-file-info?patientFileId=${patientFileId}`
  );
}

async function promoteUserToDoctor(userId: string) {
  return await httpClient.post<void>(`${URL}/promote-user-to-doctor?promotedUserId=${userId}`, {});
}

export const DoctorManagementService = {
  list: {
    request: getDoctors,
    queryKey: "doctorList"
  },
  enable: {
    request: enableDoctor,
    queryKey: "enableDoctor"
  },
  disable: {
    request: disableDoctor,
    queryKey: "disableDoctor"
  },
  details: {
    request: getDoctorDetails,
    queryKey: "details"
  },
  create: {
    request: createDoctor,
    queryKey: "createDoctor"
  },
  update: {
    request: updateDoctor,
    queryKey: "updateDoctor"
  },
  availabilitySlots: {
    request: getAvailabilitySlots,
    queryKey: "availabilitySlots"
  },
  delete: {
    request: deleteDoctor,
    queryKey: "deleteDoctor"
  },
  uploadSignature: {
    request: uploadSignature,
    queryKey: "uploadSignature"
  },
  downloadSignature: {
    request: getSignature,
    queryKey: "downloadSignature"
  },
  specialitiesLocationsForDoctor: {
    request: getSpecialitiesLocationsForDoctor,
    queryKey: "getSpecialitiesLocationsForDoctor"
  },
  doctorAssignedToPatientFile: {
    request: getDoctorAssignedToPatientFile,
    queryKey: "getDoctorAssignedToPatientFile"
  },
  promoteToDoctor: {
    request: promoteUserToDoctor,
    queryKey: "promoteUserToDoctor"
  },
  getDoctorAvailabilitySlotsAndAppointments: {
    request: getDoctorAvailabilitySlotsAndAppointments,
    queryKey: "getDoctorAvailabilitySlotsAndAppointments"
  }
};
