import { useQuery } from "@tanstack/react-query";
import { AuthService } from "api";
import { useProfileQuery } from "hooks/users";
import { useStore } from "stores";
export const useLogin = () => {
  const {
    userPermissionsStore: { setPermissions }
  } = useStore();
  const { onFetchProfile } = useProfileQuery();
  const loginQuery = useQuery({
    queryKey: [AuthService.login.queryKey],
    queryFn: AuthService.login.request,
    enabled: false,
    retry: false
  });

  function onLogin() {
    loginQuery.refetch().then(async () => {
      onFetchProfile()
        .then(async userDtoResponse => {
          setPermissions(userDtoResponse.data?.roles ?? []);
        })
        .catch(console.error);
    });
  }

  return {
    onLogin,
    ...loginQuery
  };
};
