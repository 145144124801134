import { AppRoutes } from "router/AppRoutes";
import AuthorizationGuard from "router/AuthorizationGuard";
import { AppPermissions } from "app-constants";
import { WithLoadable } from "components/app-loader";
import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import { FormLayout } from "layout";

const LocationListPage = WithLoadable(lazy(() => import("pages/management/locations/LocationListPage")));
const LocationProfilePage = WithLoadable(lazy(() => import("pages/management/locations/LocationProfilePage")));
const ManagementHomePage = WithLoadable(lazy(() => import("pages/management/ManagementHomePage")));
const RoleUpdatePage = WithLoadable(lazy(() => import("pages/management/roles/RoleDetailsPage")));
const RoleListPage = WithLoadable(lazy(() => import("pages/management/roles/RoleListPage")));
const AdjustersListPage = WithLoadable(lazy(() => import("pages/adjusters-management/AdjustersListPage")));
const AttorneyListPage = WithLoadable(lazy(() => import("pages/attorney-management/AttorneyListPage")));
const AdjusterProfilePage = WithLoadable(lazy(() => import("pages/adjusters-management/AdjusterProfilePage")));
const AttorneyProfilePage = WithLoadable(lazy(() => import("pages/attorney-management/AttorneyProfilePage")));
const AttorneyCreatePage = WithLoadable(lazy(() => import("pages/attorney-management/AttorneyCreatePage")));
const AdjusterCreatePage = WithLoadable(lazy(() => import("pages/adjusters-management/AdjusterCreatePage")));
const AttorneyUpdatePage = WithLoadable(lazy(() => import("pages/attorney-management/AttorneyUpdatePage")));
const AdjusterUpdatePage = WithLoadable(lazy(() => import("pages/adjusters-management/AdjusterUpdatePage")));
const LocationCreatePage = WithLoadable(lazy(() => import("pages/management/locations/LocationCreatePage")));
const LocationUpdatePage = WithLoadable(lazy(() => import("pages/management/locations/LocationUpdatePage")));

export const ManagementRoutes: RouteObject = {
  path: AppRoutes.management.root,
  children: [
    { index: true, element: <ManagementHomePage /> },
    {
      path: AppRoutes.locations.root,
      element: <AuthorizationGuard permissions={[AppPermissions.LocationsManagement.Actions.View]} />,
      children: [
        { index: true, element: <LocationListPage /> },
        {
          path: `${AppRoutes.locations.profile}/:locationId`,
          element: <LocationProfilePage />
        }
      ]
    },
    {
      path: AppRoutes.roles.root,
      element: <AuthorizationGuard permissions={[AppPermissions.RoleManagement.Actions.View]} />,
      children: [
        { index: true, element: <RoleListPage /> },
        {
          path: `${AppRoutes.roles.update}/:roleId`,
          element: <RoleUpdatePage />
        }
      ]
    },
    {
      path: AppRoutes.adjusters.root,
      element: <AuthorizationGuard permissions={[AppPermissions.AdjustersManagement.Actions.View]} />,
      children: [
        { index: true, element: <AdjustersListPage /> },
        {
          path: `${AppRoutes.adjusters.profile}/:adjusterId`,
          element: <AdjusterProfilePage />
        }
      ]
    },
    {
      path: AppRoutes.attorneys.root,
      element: <AuthorizationGuard permissions={[AppPermissions.AttorneyManagement.Actions.View]} />,
      children: [
        { index: true, element: <AttorneyListPage /> },
        {
          path: `${AppRoutes.attorneys.profile}/:attorneyId`,
          element: <AttorneyProfilePage />
        }
      ]
    }
  ]
};

export const ManagementFormsRoutes: RouteObject = {
  path: AppRoutes.management.root,
  children: [
    {
      path: AppRoutes.locations.root,
      element: <FormLayout />,
      children: [
        {
          path: AppRoutes.locations.create,
          element: <AuthorizationGuard permissions={[AppPermissions.LocationsManagement.Actions.Create]} />,
          children: [{ index: true, element: <LocationCreatePage /> }]
        },
        {
          path: `${AppRoutes.locations.update}/:locationId`,
          element: <AuthorizationGuard permissions={[AppPermissions.LocationsManagement.Actions.Update]} />,
          children: [{ index: true, element: <LocationUpdatePage /> }]
        }
      ]
    },
    {
      path: AppRoutes.attorneys.root,
      element: <FormLayout />,
      children: [
        {
          path: AppRoutes.attorneys.create,
          element: <AuthorizationGuard permissions={[AppPermissions.AttorneyManagement.Actions.Create]} />,
          children: [{ index: true, element: <AttorneyCreatePage /> }]
        },
        {
          path: `${AppRoutes.attorneys.update}/:attorneyId`,
          element: <AuthorizationGuard permissions={[AppPermissions.AttorneyManagement.Actions.Update]} />,
          children: [{ index: true, element: <AttorneyUpdatePage /> }]
        }
      ]
    },
    {
      path: AppRoutes.adjusters.root,
      element: <FormLayout />,
      children: [
        {
          path: AppRoutes.adjusters.create,
          element: <AuthorizationGuard permissions={[AppPermissions.AdjustersManagement.Actions.Create]} />,
          children: [{ index: true, element: <AdjusterCreatePage /> }]
        },
        {
          path: `${AppRoutes.adjusters.update}/:adjusterId`,
          element: <AuthorizationGuard permissions={[AppPermissions.AdjustersManagement.Actions.Update]} />,
          children: [{ index: true, element: <AdjusterUpdatePage /> }]
        }
      ]
    }
  ]
};
