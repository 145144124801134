import { PresetColors } from "types";
import { PaletteOptions } from "@mui/material/styles";

export default function themePalette(theme: PresetColors): PaletteOptions {
  return {
    mode: "light",
    primary: {
      light: theme.primary100,
      main: theme.primaryMain,
      dark: theme.primary500,
      200: theme.primary200,
      300: theme.primary300,
      400: theme.primary400,
      500: theme.primary500
    },
    secondary: {
      light: theme.secondary100,
      main: theme.secondaryMain,
      dark: theme.secondary500,
      200: theme.secondary200,
      300: theme.secondary300,
      400: theme.secondary400,
      500: theme.secondary500
    },
    error: {
      main: theme.statusDanger
    },
    warning: {
      main: theme.statusWarningMain
    },
    success: {
      main: theme.statusSuccess
    },
    grey: {
      50: theme.gray50,
      100: theme.gray100,
      200: theme.gray200,
      300: theme.gray300,
      400: theme.gray400,
      500: theme.gray500
    },
    background: {
      paper: theme.backgroundMain,
      default: theme.backgroundMain
    }
  };
}
