function NotificationIcon() {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='16' cy='16' r='16' fill='#D6D7E4' />
      <path
        d='M18.0709 19.7656V21.6669C18.0709 22.627 17.1261 23.4052 15.9602 23.4052C14.7944 23.4052 13.8496 22.627 13.8496 21.6669V19.7656H18.0709Z'
        fill='#303778'
      />
      <path
        d='M20.5175 21.5609C19.8423 20.5657 19.6363 18.4186 20.0686 16.1036C20.4646 13.9851 19.2933 12.9843 18.2809 12.4152C18.2144 12.3801 18.156 12.3314 18.1095 12.2723C18.063 12.2131 18.0294 12.1449 18.011 12.072C17.9595 11.7589 17.8128 11.4694 17.591 11.2427C17.3691 11.0159 17.0828 10.863 16.771 10.8047C16.4592 10.7463 16.137 10.7854 15.8482 10.9166C15.5594 11.0478 15.318 11.2648 15.1568 11.538C15.1133 11.5991 15.0574 11.6504 14.9927 11.6884C14.9281 11.7265 14.8561 11.7505 14.7816 11.7588C13.6303 11.9233 12.1774 12.4295 11.78 14.5504C11.3451 16.8636 10.3757 18.792 9.38603 19.4751C8.97726 19.7585 9.18138 20.3378 9.78476 20.4508L19.7974 22.3256C20.3973 22.4384 20.7955 21.971 20.5175 21.5609Z'
        fill='#303778'
      />
      <path
        d='M8.02304 15.2138C7.87625 13.45 8.43507 11.6999 9.5769 10.3476C10.7187 8.99521 12.3503 8.15095 14.1138 8L14.1593 8.52627C12.5351 8.665 11.0322 9.44236 9.98053 10.6878C8.92881 11.9332 8.41409 13.545 8.54931 15.1695L8.02304 15.2138Z'
        fill='#303778'
      />
      <path
        d='M9.61953 15.0789C9.50643 13.738 9.93056 12.4072 10.7986 11.379C11.6667 10.3509 12.9077 9.7097 14.2485 9.59647L14.2929 10.1227C13.698 10.1729 13.1189 10.3398 12.5885 10.6138C12.0582 10.8878 11.587 11.2635 11.2019 11.7197C10.8168 12.1758 10.5253 12.7032 10.344 13.272C10.1628 13.8408 10.0953 14.4397 10.1455 15.0345L9.61953 15.0789ZM23.3221 18.0791L22.8468 17.8473C23.5624 16.3822 23.6668 14.6929 23.1369 13.1509C22.6071 11.6088 21.4864 10.3405 20.0214 9.62472L20.2532 9.14941C21.0412 9.53411 21.7456 10.0703 22.3263 10.7273C22.907 11.3843 23.3526 12.1493 23.6376 12.9785C23.9226 13.8077 24.0414 14.685 23.9873 15.5602C23.9331 16.4354 23.7071 17.2913 23.3221 18.0791Z'
        fill='#303778'
      />
      <path
        d='M21.8821 17.3757L21.4068 17.1439C21.6689 16.6076 21.8228 16.0249 21.8596 15.4291C21.8965 14.8333 21.8157 14.236 21.6218 13.6715C21.4278 13.1069 21.1246 12.5861 20.7294 12.1387C20.3342 11.6914 19.8547 11.3262 19.3184 11.0642L19.5502 10.5889C20.7585 11.1806 21.6826 12.2276 22.1198 13.5C22.557 14.7724 22.4715 16.1663 21.8821 17.3757Z'
        fill='#303778'
      />
    </svg>
  );
}

export default NotificationIcon;
