import { ApiError } from "types";
import { NotificationsService } from "api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ErrorsUtils } from "utils";

export const useMarkNotificationAsReadOrUnreadMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [NotificationsService.markAsReadOrUnread.mutationKey],
    mutationFn: NotificationsService.markAsReadOrUnread.request,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [NotificationsService.notifications.queryKey]
      });
    },
    onError: (error: ApiError) => {
      ErrorsUtils.renderApiErrors(error);
    }
  });

  const markNotificationAsReadOrUnread = (notificationId: string) => {
    return mutation.mutateAsync(notificationId);
  };

  return {
    ...mutation,
    markNotificationAsReadOrUnread
  };
};
