import { PropsWithChildren } from "react";
import { useAuthorization } from "hooks";
import { UserRoles } from "app-constants";

type AuthorizationWrapperProps = PropsWithChildren & {
  permissions: string[];
  roles?: UserRoles[];
};
function AuthorizationWrapper({ permissions, children, roles }: AuthorizationWrapperProps) {
  const { hasAccess, hasRoles } = useAuthorization(permissions ?? [], roles);

  if (roles && roles.length > 0 && !hasRoles) return null;

  if (!hasAccess && !hasRoles) return null;

  return <>{children}</>;
}

export default AuthorizationWrapper;
