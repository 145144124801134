import React, { useMemo } from "react";
import {
  AppointmentFoldersListDto,
  DocumentForReadDto,
  FileStatus,
  FolderForListDto
} from "types";
import {
  useDeleteDocumentMutation,
  useDownloadDocumentMutation,
  useEditFileStatus,
  useFolderManagement,
  useGetDocumentURLQuery,
  useDocumentManagement,
  useFolderManagementState,
  useFoldersQuery,
  useIsExpectedMutation,
  useUploadDocumentMutation
} from "hooks/documents";

export const DocumentService = {
  deleteDocument: useDeleteDocumentMutation,
  downloadDocument: useDownloadDocumentMutation,
  editFileStatus: useEditFileStatus,
  folderManagement: useFolderManagement,
  documentManagement: useDocumentManagement,
  folderManagementState: useFolderManagementState,
  folders: useFoldersQuery,
  isExpectedMutation: useIsExpectedMutation,
  uploadDocument: useUploadDocumentMutation,
  getDocumentURL: useGetDocumentURLQuery
};
export type TargetFolderKey =
  | keyof AppointmentFoldersListDto
  | "doctor"
  | "patientFile";

export type DocumentServiceContextType = {
  entityId: string;
  URL: string;
  isFoldersLoading: boolean;
  isDeleteLoading: boolean;
  onDeleteDocument: (folderId: string, documentId: DocumentForReadDto) => void;
  onDownloadDocument: (folderId: string, document: DocumentForReadDto) => void;
  onUploadFilesHandler: (folderId: string, files: File[]) => Promise<void>;
  onEditFileStatus: (status: FileStatus, folderId: string | null) => void;
  getTargetFolders(targetFolderKey: TargetFolderKey): FolderForListDto[];
  onFetchFolders: () => Promise<void>;
  onGetDocumentURL(folderId: string, document: DocumentForReadDto): void;
  isGettingDocumentURLLoading: boolean;
  error: unknown;
};

export const DocumentServiceContext =
  React.createContext<DocumentServiceContextType>({
    ...DocumentService,
    entityId: "",
    URL: "",
    isFoldersLoading: false,
    isDeleteLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDeleteDocument: (folderId: string, document: DocumentForReadDto) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onDownloadDocument: (folderId: string, document: DocumentForReadDto) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUploadFilesHandler: async (folderId: string, files: File[]) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onEditFileStatus: (status: FileStatus, folderId: string | null) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTargetFolders: (targetFolderKey: TargetFolderKey) => [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onGetDocumentURL: (folderId: string, document: DocumentForReadDto) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onFetchFolders: async () => {},
    isGettingDocumentURLLoading: false,
    error: null
  });

function DocumentServiceProvider({
  children,
  entityId,
  URL
}: {
  children: React.ReactNode;
  entityId: string;
  URL: string;
}) {
  const {
    folders,
    isLoading: isFoldersLoading,
    isDeleteLoading,
    onFetchFolders,
    onDeleteDocument,
    onDownloadDocument,
    onUploadFilesHandler,
    onEditFileStatus,
    error,
    onGetDocumentURL,
    isGettingDocumentURLLoading
  } = useFolderManagement<AppointmentFoldersListDto | FolderForListDto[]>(
    URL,
    entityId
  );

  const getTargetFoldersByKey = (
    targetFolderKey: TargetFolderKey
  ): FolderForListDto[] => {
    if (targetFolderKey === "doctor" || targetFolderKey === "patientFile") {
      return folders as FolderForListDto[];
    }

    return (
      (folders as AppointmentFoldersListDto)?.[targetFolderKey]?.folders ?? []
    );
  };

  // Use useMemo to avoid unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      ...DocumentService,
      entityId,
      URL,
      isFoldersLoading,
      isDeleteLoading,
      onDeleteDocument,
      onDownloadDocument,
      onUploadFilesHandler,
      onEditFileStatus,
      onGetDocumentURL,
      isGettingDocumentURLLoading,
      error,
      onFetchFolders,
      getTargetFolders: getTargetFoldersByKey
    }),
    [
      entityId,
      URL,
      folders,
      isFoldersLoading,
      isDeleteLoading,
      isGettingDocumentURLLoading
    ]
  );

  return (
    <DocumentServiceContext.Provider value={contextValue}>
      {children}
    </DocumentServiceContext.Provider>
  );
}
export default DocumentServiceProvider;
