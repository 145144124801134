import React, { Fragment } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "stores";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { PrimeLoadingButton, SecondaryCard, SlideTransition } from "components/common";
import { Close } from "@mui/icons-material";

function ConfirmModalContainer() {
  const { confirmModalStore } = useStore();
  const { modal } = confirmModalStore;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCancel = () => {
    confirmModalStore.close();
  };

  const handleOk = () => {
    confirmModalStore.confirm();
  };

  const showCustomActions =
    !!modal.props?.options?.dialogActions;

  const renderedCustomActions =
    modal.props?.options?.dialogActions?.map(button => (
      <Fragment key={button.id}>{button.render()}</Fragment>
    ));

  const renderedDialogActions = showCustomActions ? (
    renderedCustomActions
  ) : (
    <>
      <PrimeLoadingButton loading={modal.props?.isLoading} color='secondary' variant='contained' onClick={handleOk}>
        {modal.props?.buttonLabel || "Confirm"}
      </PrimeLoadingButton>
    </>
  );

  const isOpen = modal.open;

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      keepMounted
      fullScreen={fullScreen}
      onClose={confirmModalStore.close}
      open={isOpen}
      fullWidth
      aria-labelledby='responsive-dialog-title'
      sx={{
        zIndex: 10001
      }}
    >
      <DialogTitle>{modal.props?.title}</DialogTitle>
      <Box position='absolute' top={2} right={2}>
        <IconButton onClick={handleCancel}>
          <Close fontSize="large" />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <SecondaryCard>{modal.props?.body}</SecondaryCard>
      </DialogContent>
      <DialogActions>{renderedDialogActions}</DialogActions>
    </Dialog>
  );
}

export default observer(ConfirmModalContainer);
