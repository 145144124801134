import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { PrimeMenu } from "components/common/menu";

type FilterMenuProps = PropsWithChildren & {
  isMenuOpen: boolean;
  handleMenuClose(): void;
  anchorEl: HTMLElement | null;
};

function FilterMenu({ children, handleMenuClose, isMenuOpen, anchorEl }: FilterMenuProps) {
  return (
    <PrimeMenu
      anchorEl={anchorEl}
      id='filter-menu'
      open={isMenuOpen}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            minWidth: 320,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Typography py={1} pl={2} sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
        Filter
      </Typography>
      {children}
    </PrimeMenu>
  );
}

export default FilterMenu;
